import { get } from 'lodash';
import {
    snakeCaseToSentenceCase,
    serverSourceToClientSource,
    clientSourceToServerSource,
} from '@/utils/lib';
import { SOURCES, SOURCE, DEFAULT_SOURCE_QUERY } from '@/components/pfp/const';
import { getPersonalInformation } from '@/utils/pfp2/dataleaks/getPersonalInformation';
import {
    getContactInformation,
    CI_KEY,
} from '@/utils/pfp2/dataleaks/getContactInformation';
import { getNames } from '@/utils/pfp2/dataleaks/getNames';
import { getProfileURLs } from '@/utils/pfp2/dataleaks/getProfileURLs';
import { getUsernames } from '@/utils/pfp2/dataleaks/getUsernames';
import { getIPAddresses } from '@/utils/pfp2/dataleaks/getIPAddresses';
import { getHouseholdInformation } from '@/utils/pfp2/dataleaks/getHouseholdInformation';
import { getLocations } from '@/utils/pfp2/dataleaks/getLocations';
import { getIdentifiers } from '@/utils/pfp2/dataleaks/getIdentifiers';
import { getMisc } from '@/utils/pfp2/dataleaks/getMisc';
import { generateProfileURLs } from '@/utils/pfp2/dataleaks/generateProfileURLs';

export function mapNetworks(results) {
    return results
        .filter((result) => result.source !== SOURCE.PIPL)
        .map((result) => {
            let sources = [serverSourceToClientSource(result.source)];

            if (!sources[0]) {
                sources = SOURCES.filter(
                    (source) => DEFAULT_SOURCE_QUERY[source],
                );
            }

            let attributes = [];

            for (const [key, value] of Object.entries(result.raw)) {
                if (key.startsWith('unknown')) {
                    continue;
                }

                attributes.push({
                    name: snakeCaseToSentenceCase(key),
                    value: value,
                    source: serverSourceToClientSource(result.source),
                });
            }

            return {
                id: result.id,
                name: snakeCaseToSentenceCase(result.network),
                sources,
                raw: result.raw,
                attributes,
            };
        });
}

export function mapGumtree(networks) {
    if (!networks.length) {
        return [];
    }

    let data = [];

    networks.forEach((network) => {
        const images = network.attributes
            .filter((attr) => attr.name.toLowerCase() === 'images')
            .map((attr) => attr.value)
            .flat();

        const attributes = network.attributes;

        if (!attributes.length) {
            return;
        }

        const price = attributes.find(
            (attr) => attr.name.toLowerCase() === 'price',
        )?.value;
        const title = attributes.find(
            (attr) => attr.name.toLowerCase() === 'title',
        )?.value;
        const author = attributes.find(
            (attr) => attr.name.toLowerCase() === 'author',
        )?.value;
        const listingId = attributes.find(
            (attr) => attr.name.toLowerCase() === 'listing id',
        )?.value;
        const description = attributes.find(
            (attr) => attr.name.toLowerCase() === 'description',
        )?.value;
        const accountId = attributes.find(
            (attr) => attr.name.toLowerCase() === 'account id',
        )?.value;
        const postcode = attributes.find(
            (attr) => attr.name.toLowerCase() === 'postcode',
        )?.value;
        const modifiedDate = attributes.find(
            (attr) => attr.name.toLowerCase() === 'modification date time',
        )?.value;

        data.push({
            id: network.id,
            name: network.name,
            price,
            description,
            title,
            author,
            listingId,
            images,
            attributes,
            accountId,
            postcode,
            modifiedDate,
        });
    });

    return data;
}

export function mapPipl(results) {
    const piplResults = results.filter(
        (result) => result.source === SOURCE.PIPL,
    );

    if (!piplResults.length) {
        return [];
    }

    const res = !piplResults[0].raw.urls
        ? piplResults[0].raw.person
        : piplResults[0].raw;

    // remove "full.email.available@business.subscription" from res.data.emails[ix]
    const data = {
        ...res,
        emails: res.emails
            ? res.emails.filter(
                  (email) =>
                      email.address !==
                      'full.email.available@business.subscription',
              )
            : [],
    };

    const pipl = {
        id: piplResults[0].id,
        data,
        url: res.url,
    };

    return pipl;
}

function mapDataLeaks(results) {
    if (!results.length) {
        return {};
    }

    const pi = [];
    const names = [];
    const contact = [];
    const profileURLs = [];
    const usernames = [];
    const ipAddresses = [];
    const household = [];
    const locations = [];
    const identifiers = [];
    const generatedProfileURLs = [];
    const misc = [];

    for (let i = 0; i < results.length; i++) {
        const result = results[i];

        const _pi = getPersonalInformation(result);
        if (Object.keys(_pi).length) {
            pi.push(_pi);
        }

        const _names = getNames(result);
        if (Object.keys(_names).length) {
            names.push(_names);
        }

        const _contact = getContactInformation(result);
        if (Object.keys(_contact).length) {
            contact.push(_contact);
        }

        const _profileURLs = getProfileURLs(result);
        if (Object.keys(_profileURLs).length) {
            profileURLs.push(_profileURLs);
        }

        const _usernames = getUsernames(result);
        if (Object.keys(_usernames).length) {
            usernames.push(_usernames);
        }

        const _ipAddresses = getIPAddresses(result);
        if (Object.keys(_ipAddresses).length) {
            ipAddresses.push(_ipAddresses);
        }

        const _household = getHouseholdInformation(result);
        if (Object.keys(_household).length) {
            household.push(_household);
        }

        const _locations = getLocations(result);
        if (Object.keys(_locations).length) {
            locations.push(_locations);
        }

        const _identifiers = getIdentifiers(result);
        if (Object.keys(_identifiers).length) {
            identifiers.push(_identifiers);
        }

        const _generatedProfileURLs = generateProfileURLs(_identifiers);
        if (Object.keys(_generatedProfileURLs).length) {
            generatedProfileURLs.push(_generatedProfileURLs);
        }

        const _misc = getMisc(result);
        if (Object.keys(_misc).length) {
            misc.push(_misc);
        }
    }

    const uniqueEmails = contact
        .reduce((acc, curr) => {
            if (curr[CI_KEY.EMAILS]) {
                curr[CI_KEY.EMAILS].forEach((e) => acc.push(e.toLowerCase()));
            }

            return acc;
        }, [])
        .filter((item, index, self) => self.indexOf(item) === index);

    const uniquePhones = contact
        .reduce((acc, curr) => {
            if (curr[CI_KEY.PHONE_NUMBERS]) {
                curr[CI_KEY.PHONE_NUMBERS].forEach((p) => {
                    acc.push(p);
                });
            }

            return acc;
        }, [])
        .filter((item, index, self) => self.indexOf(item) === index);

    return {
        personalInformation: pi,
        names,
        contactInformation: contact,
        profileURLs,
        usernames,
        ipAddresses,
        householdInformation: household,
        locations,
        identifiers,
        generatedProfileURLs,
        misc,
        uniqueEmails,
        uniquePhones,
    };
}

export function mapResponseToResult(response) {
    if (!response) {
        console.error('Invalid response passed to mapResponseToResult()');
        return;
    }

    const body = response.response;

    if (!body) {
        console.error('Invalid response passed to mapResponseToResult()');
        console.error('Response data is undefined');
        return;
    }

    if (!body.config || !body.config.checks) {
        console.error('Invalid response passed to mapResponseToResult()');
        console.error('Response data is missing required config');
        return;
    }

    const serverSources = Object.entries({ ...body.config.checks })
        .map(([key, value]) => {
            if (value === true) {
                return serverSourceToClientSource(key);
            }
        })
        .filter(Boolean)
        .sort((a, b) => {
            if (a.startsWith('nw') && !b.startsWith('nw')) {
                return -1;
            }

            if (!a.startsWith('nw') && b.startsWith('nw')) {
                return 1;
            }

            return a.localeCompare(b);
        });

    if (!body.results) {
        return {
            id: body.id,
            reference: body.data.metadata.client_reference,
            query: body.data.phone || body.data.email,
            sourcesSearched: serverSources || [],
            sourcesMatched: [],
            startedDate: new Date(response.created_at),
            completedDate: new Date(response.updated_at),
            loading: true,
            team: response.team,
            user: response.user,
            data: {
                profiles: {},
                networks: [],
                addSource: [],
                gumtree: [],
                pipl: [],
                dataLeaks: {},
            },
        };
    }

    const results = body.results
        .filter((result) => !Array.isArray(result.raw) || !Object.keys(result.raw).length)
        .map((result) => {
            return {
                ...result,
                source: result.source,
            };
        });

    const networks = mapNetworks(results);

    const gumtree = mapGumtree(
        networks.filter(
            (network) => (network.name || '').toLowerCase() === 'gumtree',
        ),
    );

    const profiles = mapProfiles(results);

    const addSource = SOURCES.filter(
        (source) => !serverSources.includes(source),
    );

    const pipl = mapPipl(results);

    const dataLeaks = mapDataLeaks(
        results.filter(
            (r) =>
                r.source === clientSourceToServerSource(SOURCE.LEAKED_DATASETS),
        ),
    );

    // sources that have matched in the results
    const matched = SOURCES.filter((source) =>
        results.some((result) => {
            const serverSource = clientSourceToServerSource(source);

            if (!result.source) {
                return SOURCE.NW_DATA === source;
            }

            if (Array.isArray(serverSource)) {
                return serverSource.some((s) => result.source === s);
            }

            return result.source === serverSource;
        }),
    );

    return {
        id: body.id,
        reference: body.data.metadata.client_reference,
        query: body.data.phone || body.data.email,
        sourcesSearched: serverSources || [],
        sourcesMatched: matched || [],
        startedDate: new Date(response.created_at),
        completedDate: new Date(response.updated_at),
        loading: false,
        results,
        team: response.team,
        user: response.user,
        data: {
            profiles,
            networks,
            addSource,
            gumtree,
            pipl,
            dataLeaks,
        },
    };
}

export function mergeLinkedResults(original, linked) {
    const networks = [
        ...(original.data.networks || {}),
        ...(linked.data.networks || {}),
    ];
    const profiles = [
        ...(original.data.profiles || {}),
        ...(linked.data.profiles || {}),
    ];
    const attributes = [
        ...(original.data.attributes || {}),
        ...(linked.data.attributes || {}),
    ];
    const addSource = [
        ...(original.data.addSource || {}),
        ...(linked.data.addSource || {}),
    ];
    const gumtree = [
        ...(original.data.gumtree || {}),
        ...(linked.data.gumtree || {}),
    ];
    const pipl = [...(original.data.pipl || {}), ...(linked.data.pipl || {})];

    return {
        id: original.id,
        query: original.query,
        reference: original.reference,
        startedDate: original.startedDate,
        completedDate: linked.completedDate,
        sourcesSearched: [
            ...original.sourcesSearched,
            ...linked.sourcesSearched,
        ],
        sourcesMatched: [...original.sourcesMatched, ...linked.sourcesMatched],
        data: {
            networks,
            profiles,
            attributes,
            addSource,
            gumtree,
            pipl,
        },
    };
}

/*
  {
    network: string
    image: string
    name: string
    email: string
    phone: string
    url: string
    accountExists?: boolean // flag to display ui differently, less info available
    linkedAccounts: {
      name: string
    }
    otherAttributes: {
      name: string
    }
  }
*/

export function mapProfiles(results) {
    let profiles = {};

    if (!results.length) {
        return {};
    }

    results.forEach((network) => {
        let data = {};

        const query = network.value.current;
        const isEmail = query.includes('@');

        if (serverSourceToClientSource(network.source) !== SOURCE.NW_OSINT) {
            if (
                !network.network &&
                serverSourceToClientSource(network.source) !== SOURCE.PIPL
            ) {
                if (
                    network.url.includes('linkedin') &&
                    !network.url.includes('undefined')
                ) {
                    data = {
                        network: 'Linkedin',
                        source: SOURCE.NW_DATA,
                        name: get(network, 'raw.name'),
                        email:
                            get(network, 'raw.email') ||
                            (isEmail ? query : null),
                        phone: !isEmail ? query : null,
                        url: network.url,
                        otherAttributes: {
                            name: {
                                label: 'Name',
                                value: get(network, 'raw.name'),
                            },
                            url: {
                                label: 'Linkedin URL',
                                value: network.url,
                            },
                            profileId: {
                                label: 'Profile ID',
                                value: get(network, 'raw.profile'),
                            },
                        },
                    };
                }
            }

            switch (network.network) {
                case 'facebook':
                    let fbUrl = undefined;

                    if (get(network, 'raw.0.uid')) {
                        fbUrl =
                            'https://www.facebook.com/' +
                            get(network, 'raw.0.uid');
                    }

                    if (
                        network.url &&
                        network.url !== 'https://www.facebook.com/0'
                    ) {
                        fbUrl = network.url;
                    }

                    if (!fbUrl) {
                        break;
                    }

                    data = {
                        network: 'Facebook',
                        source: serverSourceToClientSource(network.source),
                        phone: get(network, 'raw.0.mobile'),
                        email: isEmail ? query : null,
                        source: SOURCE.NW_DATA,
                        url: fbUrl,
                        otherAttributes: {
                            uid: {
                                label: 'Facebook UID',
                                value: get(network, 'raw.0.uid'),
                            },
                            mobile: {
                                label: 'Mobile',
                                value: get(network, 'raw.0.mobile'),
                            },
                        },
                    };
                    break;
            }
        }

        if (serverSourceToClientSource(network.source) === SOURCE.NW_OSINT) {
            switch (network.network) {
                case 'ebay':
                    data = {
                        network: 'Ebay',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.first_name'),
                        image: get(network, 'raw.image'),
                        url: network.url || get(network, 'raw.profile_url'),
                        phone: !isEmail ? query : null,
                        email: isEmail ? query : null,
                        otherAttributes: {
                            bio: {
                                label: 'Bio',
                                value: get(network, 'raw.bio'),
                            },
                            name: {
                                label: 'Name',
                                value: get(network, 'raw.first_name'),
                            },
                            image: {
                                label: 'Image',
                                value: get(network, 'raw.image'),
                            },
                            removed: {
                                label: 'Removed',
                                value: get(network, 'raw.removed')
                                    ? 'Yes'
                                    : 'No',
                            },
                            isUser: {
                                label: 'Is user',
                                value: get(network, 'raw.is_user')
                                    ? 'Yes'
                                    : 'No',
                            },
                            isStore: {
                                label: 'Is store',
                                value: get(network, 'raw.is_store')
                                    ? 'Yes'
                                    : 'No',
                            },
                            location: {
                                label: 'Location',
                                value: get(network, 'raw.location'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            firstName: {
                                label: 'First name',
                                value: get(network, 'raw.first_name'),
                            },
                            itemsSold: {
                                label: 'Items sold',
                                value: get(network, 'raw.items_sold'),
                            },
                            phoneHint: {
                                label: 'Phone hint',
                                value: get(network, 'raw.phone_hint'),
                            },
                            profileUrl: {
                                label: 'Profile URL',
                                value: get(network, 'raw.profile_url'),
                            },
                            memberSince: {
                                label: 'Member since',
                                value: get(network, 'raw.member_since'),
                            },
                            feedbacksUrl: {
                                label: 'Feedbacks URL',
                                value: get(network, 'raw.feedbacks_url'),
                            },
                            sellingItems: {
                                label: 'Selling items',
                                value: get(network, 'raw.selling_items'),
                            },
                            authProviders: {
                                label: 'Auth providers',
                                value: get(network, 'raw.auth_providers'),
                            },
                            neutralFeedback: {
                                label: 'Neutral feedback',
                                value: get(network, 'raw.neutral_feedback'),
                            },
                            feedbackReceived: {
                                label: 'Feedback received',
                                value: get(network, 'raw.feedback_received'),
                            },
                            negativeFeedback: {
                                label: 'Negative feedback',
                                value: get(network, 'raw.negative_feedback'),
                            },
                            positiveFeedback: {
                                label: 'Positive feedback',
                                value: get(network, 'raw.positive_feedback'),
                            },
                            positiveFeedbackPercent: {
                                label: 'Positive feedback percent',
                                value: get(
                                    network,
                                    'raw.positive_feedback_percent',
                                ),
                            },
                        },
                    };
                    break;
                case 'snapchat':
                    data = {
                        network: 'Snapchat',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.userInfo.displayName'),
                        image: get(
                            network,
                            'raw.userInfo.bitmoji3d.avatarImage.url',
                        ),
                        url:
                            network.url ||
                            'https://www.snapchat.com/add/' +
                                get(network, 'raw.userInfo.username'),
                        phone: !isEmail ? query : null,
                        email: isEmail ? query : null,
                        otherAttributes: {
                            id: {
                                label: 'Snapchat ID',
                                value: get(network, 'raw.id'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.userInfo.username'),
                            },
                            displayName: {
                                label: 'Display name',
                                value: get(network, 'raw.userInfo.displayName'),
                            },
                            mUsername: {
                                label: 'M username',
                                value: get(network, 'raw.m_username'),
                            },
                            nativeName: {
                                label: 'Native name',
                                value: get(network, 'raw.native_name'),
                            },
                            activeRecently: {
                                label: 'Active recently',
                                value: get(network, 'raw.active_recently'),
                            },
                            snapcodeImageUrl: {
                                label: 'Snapcode image URL',
                                value: get(
                                    network,
                                    'raw.userInfo.snapcodeImageUrl',
                                ),
                            },
                            avatarImage: {
                                label: 'Avatar image',
                                value: get(
                                    network,
                                    'raw.userInfo.bitmoji3d.avatarImage.url',
                                ),
                            },
                            backgroundImage: {
                                label: 'Background image',
                                value: get(
                                    network,
                                    'raw.userInfo.bitmoji3d.backgroundImage.url',
                                ),
                            },
                        },
                    };
                    break;

                case 'whatsapp':
                    data = {
                        network: 'Whatsapp',
                        source: serverSourceToClientSource(network.source),
                        image: get(network, 'raw.photo'),
                        phone:
                            get(network, 'raw.numberDetails.user') ||
                            (!isEmail ? query : null),
                        email: isEmail ? query : null,
                        otherAttributes: {
                            bio: {
                                label: 'Bio',
                                value: get(network, 'raw.bio'),
                            },
                            updatedAt: {
                                label: 'Last updated at',
                                value: get(network, 'raw.set_at'),
                            },
                        },
                    };
                    break;

                case 'github':
                    // @TODO: link to account
                    data = {
                        network: 'Github',
                        source: serverSourceToClientSource(network.source),
                        image: get(network, 'raw.avatar_url'),
                        name:
                            get(network, 'raw.name') ||
                            get(network, 'raw.login'),
                        email:
                            get(network, 'raw.email') ||
                            (isEmail ? query : null),
                        phone: !isEmail ? query : null,
                        url: network.url || get(network, 'raw.html_url'),
                        linkedAccounts: {
                            gravatar: get(network, 'raw.gravatar_id'),
                            twitter: get(network, 'raw.twitter_username'),
                        },
                        otherAttributes: {
                            bio: {
                                label: 'Bio',
                                value: get(network, 'raw.bio'),
                            },
                            login: {
                                label: 'Username',
                                value: get(network, 'raw.login'),
                            },
                            company: {
                                label: 'Company',
                                value: get(network, 'raw.company'),
                            },
                            id: {
                                label: 'Github ID',
                                value: get(network, 'raw.id'),
                            },
                            url: {
                                label: 'Github URL',
                                value: get(network, 'raw.html_url'),
                            },
                            type: {
                                label: 'User type',
                                value: get(network, 'raw.type'),
                            },
                            createdAt: {
                                label: 'Account created',
                                value: new Date(get(network, 'raw.created_at')),
                            },
                            updatedAt: {
                                label: 'Last updated at',
                                value: new Date(get(network, 'raw.updated_at')),
                            },
                        },
                    };
                    break;

                case 'canva':
                    data = {
                        network: 'Canva',
                        source: serverSourceToClientSource(network.source),
                        image: get(network, 'raw.profile'),
                        name: get(network, 'raw.display_name'),
                        email:
                            get(network, 'raw.email') ||
                            (isEmail ? query : null),
                        phone: !isEmail ? query : null,
                        otherAttributes: {
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            createdAt: {
                                label: 'Account created',
                                value: new Date(get(network, 'raw.created_at')),
                            },
                        },
                    };
                    break;

                case 'notion':
                    data = {
                        accountExists: true,
                        network: 'Notion',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.value.value.name'),
                        email:
                            get(network, 'raw.value.value.email') ||
                            (isEmail ? query : null),
                    };
                    break;

                case 'strava':
                    if (get(network, 'raw.registered')) {
                        data = {
                            accountExists: true,
                            network: 'Strava',
                            source: serverSourceToClientSource(network.source),
                            ...(isEmail ? { email: query } : { phone: query }),
                        };
                        break;
                    }

                    data = {
                        network: 'Strava',
                        source: serverSourceToClientSource(network.source),
                        image: get(network, 'raw.profile_original'),
                        name:
                            get(network, 'raw.firstname') +
                            ' ' +
                            get(network, 'raw.lastname'),
                        email:
                            get(network, 'raw.email') ||
                            (isEmail ? query : null),
                        phone: !isEmail ? query : null,
                        url:
                            network.url ||
                            'https://www.strava.com/athletes/' +
                                get(network, 'raw.id'),
                        otherAttributes: {
                            id: {
                                label: 'Strava ID',
                                value: get(network, 'raw.id'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            bio: {
                                label: 'Bio',
                                value: get(network, 'raw.bio'),
                            },
                            sex: {
                                label: 'Sex',
                                value: get(network, 'raw.sex'),
                            },
                            athleteType: {
                                label: 'Athlete type',
                                value: get(network, 'raw.athlete_type'),
                            },
                            createdAt: {
                                label: 'Account created',
                                value: new Date(get(network, 'raw.created_at')),
                            },
                            updatedAt: {
                                label: 'Last updated at',
                                value: new Date(get(network, 'raw.updated_at')),
                            },
                        },
                    };
                    break;

                case 'emailchecker':
                    const hits = get(network, 'raw');

                    if (!hits.length) {
                        return;
                    }

                    hits.forEach((hit) => {
                        const d = {
                            accountExists: true, // different ui for only showing if a user's account exists, and no other info
                            network: hit.name,
                            source: serverSourceToClientSource(network.source),
                            ...(isEmail ? { email: query } : { phone: query }),
                        };

                        if (profiles[hit.name]) {
                            profiles[hit.name].push(d);
                        } else {
                            profiles[hit.name] = [d];
                        }
                    });
                    break;

                case 'google':
                    data = {
                        network: 'Google',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.name'),
                        image: get(network, 'raw.photo'),
                        email:
                            get(network, 'raw.email') ||
                            (isEmail ? query : null),
                        phone: !isEmail ? query : null,
                        otherAttributes: {
                            id: {
                                label: 'Google GAIA ID',
                                value: get(network, 'raw.gaia_id'),
                            },
                            services: {
                                label: 'Google services',
                                value: (
                                    get(network, 'raw.google_services') || []
                                ).join(', '),
                            },
                            updatedAt: {
                                label: 'Last updated at',
                                value: new Date(
                                    get(network, 'raw.last_update') * 1000,
                                ),
                            },
                        },
                    };
                    break;

                case 'youtube':
                    data = {
                        network: 'Youtube',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.channel_name'),
                        image: get(network, 'raw.profile_picture_url'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url: network.url || get(network, 'raw.channel_url'),
                        otherAttributes: {
                            id: {
                                label: 'Youtube channel ID',
                                value: get(network, 'raw.channel_id'),
                            },
                            channelURL: {
                                label: 'Channel URL',
                                value: get(network, 'raw.channel_url'),
                            },
                            subscribers: {
                                label: 'Subscribers',
                                value: get(network, 'raw.subscriber_count'),
                            },
                            channelName: {
                                label: 'Channel name',
                                value: get(network, 'raw.channel_name'),
                            },
                            joined: {
                                label: 'Account created',
                                value: get(network, 'raw.join_date_indication'),
                            },
                            updatedAt: {
                                label: 'Last updated at',
                                value: get(network, 'raw.updated_at'),
                            },
                        },
                    };
                    break;

                case 'skype':
                    const prof = get(network, 'raw.0.nodeProfileData');

                    if (!prof) {
                        break;
                    }

                    data = {
                        network: 'Skype',
                        source: serverSourceToClientSource(network.source),
                        name: prof.name,
                        image: prof.avatarUrl,
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        otherAttributes: {
                            city: {
                                label: 'City',
                                value: prof.city,
                            },
                            country: {
                                label: 'Country',
                                value: prof.country,
                            },
                            countryCode: {
                                label: 'Country code',
                                value: prof.countryCode,
                            },
                            state: {
                                label: 'State',
                                value: prof.state,
                            },
                            skypeHandle: {
                                label: 'Skype handle',
                                value: prof.skypeHandle,
                            },
                            username: {
                                label: 'Username',
                                value: prof.skypeId,
                            },
                        },
                    };
                    break;

                case 'office365':
                    data = {
                        network: 'Office365',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.Display'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        otherAttributes: {
                            hasPassword: {
                                label: 'Has password',
                                value: get(
                                    network,
                                    'raw.Credentials.HasPassword',
                                )
                                    ? 'Yes'
                                    : null,
                            },
                            hasRemoteNGC: {
                                label: 'Has remote NGC',
                                value: get(
                                    network,
                                    'raw.Credentials.HasRemoteNGC',
                                )
                                    ? 'Yes'
                                    : null,
                            },
                            hasFido: {
                                label: 'Has FIDO',
                                value: get(network, 'raw.Credentials.HasFido')
                                    ? 'Yes'
                                    : null,
                            },
                            hasPhone: {
                                label: 'Has phone',
                                value: get(network, 'raw.Credentials.HasPhone')
                                    ? 'Yes'
                                    : null,
                            },
                            cobasiApp: {
                                label: 'Cobasi app',
                                value: get(network, 'raw.Credentials.CobasiApp')
                                    ? 'Yes'
                                    : null,
                            },
                            hasGitHubFed: {
                                label: 'Has GitHub fed',
                                value: get(
                                    network,
                                    'raw.Credentials.HasGitHubFed',
                                )
                                    ? 'Yes'
                                    : null,
                            },
                            hasGoogleFed: {
                                label: 'Has Google fed',
                                value: get(
                                    network,
                                    'raw.Credentials.HasGoogleFed',
                                )
                                    ? 'Yes'
                                    : null,
                            },
                            hasLinkedInFed: {
                                label: 'Has LinkedIn fed',
                                value: get(
                                    network,
                                    'raw.Credentials.HasLinkedInFed',
                                )
                                    ? 'Yes'
                                    : null,
                            },
                        },
                    };
                    break;

                case 'vsco':
                    data = {
                        network: 'VSCO',
                        image: get(network, 'raw.picture'),
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.name'),
                        email:
                            get(network, 'raw.email_address') ||
                            (isEmail ? query : null),
                        phone:
                            get(network, 'raw.phone_number') ||
                            (!isEmail ? query : null),
                        url:
                            network.url ||
                            'https://vsco.co/' + get(network, 'raw.username'),
                        otherAttributes: {
                            userID: {
                                label: 'User ID',
                                value: get(network, 'raw.user_id'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            createdAt: {
                                label: 'Account created',
                                value: new Date(get(network, 'raw.created_at')),
                            },
                            updatedAt: {
                                label: 'Last updated at',
                                value: get(network, 'raw.updated_at'),
                            },
                            status: {
                                label: 'Status',
                                value: get(network, 'raw.status'),
                            },
                        },
                    };
                    break;

                case 'monopolygo':
                    const monSeen = get(network, 'raw.last_seen');
                    const monPlay = get(network, 'raw.last_play');
                    data = {
                        network: 'Monopoly Go',
                        source: serverSourceToClientSource(network.source),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        name: get(network, 'raw.name'),
                        image: get(network, 'raw.pic_url'),
                        otherAttributes: {
                            id: {
                                label: 'Monopoly Go ID',
                                value: get(network, 'raw.id'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            lastSeen: {
                                label: 'Last seen',
                                value: monSeen ? new Date(monSeen) : null,
                            },
                            lastPlay: {
                                label: 'Last play',
                                value: monPlay
                                    ? new Date(monPlay / 10000)
                                    : null,
                            },
                        },
                    };
                    break;

                case 'duolingo':
                    const duoCourses = (
                        get(network, 'raw.users.0.courses') || []
                    )
                        .map((course) => {
                            return (
                                course.title + ' (' + course.fromLanguage + ')'
                            );
                        })
                        .join(', ');

                    data = {
                        network: 'Duolingo',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.users.0.username'),
                        image: 'http:' + get(network, 'raw.users.0.picture'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url:
                            network.url ||
                            'https://www.duolingo.com/profile/' +
                                get(network, 'raw.users.0.username'),
                        otherAttributes: {
                            id: {
                                label: 'Duolingo ID',
                                value: get(network, 'raw.users.0.id'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.users.0.username'),
                            },
                            streak: {
                                label: 'Streak',
                                value: get(network, 'raw.users.0.streak'),
                            },
                            courses: {
                                label: 'Courses',
                                value: duoCourses,
                            },
                            createdAt: {
                                label: 'Account created',
                                value: new Date(
                                    get(network, 'raw.users.0.creationDate'),
                                ),
                            },
                        },
                    };
                    break;

                case 'okru':
                    data = {
                        network: 'Okru',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.name'),
                        image: get(network, 'raw.pic_full'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url: network.url || get(network, 'raw.profile_link'),
                        otherAttributes: {
                            id: {
                                label: 'Okru ID',
                                value: get(network, 'raw.id'),
                            },
                            birthday: {
                                label: 'Birthday',
                                value: get(network, 'raw.birthday'),
                            },
                            age: {
                                label: 'Age',
                                value: get(network, 'raw.age'),
                            },
                            firstName: {
                                label: 'First name',
                                value: get(network, 'raw.first_name'),
                            },
                            lastName: {
                                label: 'Last name',
                                value: get(network, 'raw.last_name'),
                            },
                        },
                    };
                    break;

                case 'discord':
                    data = {
                        network: 'Discord',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.0.suggested_user.username'),
                        image:
                            'https://cdn.discordapp.com/avatars/' +
                            get(network, 'raw.0.suggested_user.id') +
                            '/' +
                            get(network, 'raw.0.suggested_user.avatar'),
                        url:
                            network.url ||
                            'https://discord.com/users/' +
                                get(network, 'raw.0.suggested_user.id'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        otherAttributes: {
                            id: {
                                label: 'Discord ID',
                                value: get(network, 'raw.0.suggested_user.id'),
                            },
                            username: {
                                label: 'Username',
                                value: get(
                                    network,
                                    'raw.0.suggested_user.username',
                                ),
                            },
                            discriminator: {
                                label: 'Discriminator',
                                value: get(
                                    network,
                                    'raw.0.suggested_user.discriminator',
                                ),
                            },
                            globalName: {
                                label: 'Global name',
                                value: get(
                                    network,
                                    'raw.0.suggested_user.global_name',
                                ),
                            },
                            publicFlags: {
                                label: 'Public flags',
                                value: get(
                                    network,
                                    'raw.0.suggested_user.public_flags',
                                ),
                            },
                        },
                    };
                    break;

                case 'baidu':
                    data = {
                        accountExists: true,
                        network: 'Baidu',
                        source: serverSourceToClientSource(network.source),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                    };
                    break;

                case 'shotgun':
                    data = {
                        network: 'Shotgun',
                        source: serverSourceToClientSource(network.source),
                        name:
                            get(network, 'raw.0.firstName') +
                            ' ' +
                            get(network, 'raw.0.lastName'),
                        email:
                            get(network, 'raw.0.email') ||
                            (isEmail ? query : null),
                        phone: !isEmail ? query : null,
                        image: get(network, 'raw.0.avatar'),
                        otherAttributes: {
                            id: {
                                label: 'Shotgun ID',
                                value: get(network, 'raw.0.id'),
                            },
                            rewardScore: {
                                label: 'Reward score',
                                value: get(network, 'raw.0.rewardScore'),
                            },
                        },
                    };
                    break;

                case 'runkeeper':
                    data = {
                        network: 'Runkeeper',
                        source: serverSourceToClientSource(network.source),
                        name:
                            get(network, 'raw.displayName') ||
                            get(network, 'raw.username'),
                        email:
                            get(network, 'raw.email') ||
                            (isEmail ? query : null),
                        phone: !isEmail ? query : null,
                        image: get(network, 'raw.avatarUrl'),
                        url:
                            network.url ||
                            'https://runkeeper.com/user/' +
                                get(network, 'raw.username'),
                        otherAttributes: {
                            id: {
                                label: 'Runkeeper ID',
                                value: get(network, 'raw.id'),
                            },
                            uuid: {
                                label: 'UUID',
                                value: get(network, 'raw.uuid'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            publicActivities: {
                                label: 'Public activities',
                                value: get(network, 'raw.publicActivities'),
                            },
                            publicTotalActivities: {
                                label: 'Public total activities',
                                value: get(
                                    network,
                                    'raw.publicTotalActivities',
                                ),
                            },
                            publicCurrMonthActivityCount: {
                                label: 'Public current month activity count',
                                value: get(
                                    network,
                                    'raw.publicCurrMonthActivityCount',
                                ),
                            },
                            isElite: {
                                label: 'Is elite',
                                value: get(network, 'raw.isElite'),
                            },
                            useFacebookPic: {
                                label: 'Use Facebook picture',
                                value: get(network, 'raw.useFacebookPic'),
                            },
                            createdAt: {
                                label: 'Account created',
                                value: new Date(
                                    get(network, 'raw.creationDate'),
                                ),
                            },
                            fbuid: {
                                label: 'FB UID',
                                value: get(network, 'raw.fbuid'),
                            },
                            facebookId: {
                                label: 'Facebook ID',
                                value: get(network, 'raw.facebookId'),
                            },
                        },
                    };
                    break;

                case 'truecaller':
                    data = {
                        network: 'Truecaller',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.data.0.name'),
                        phone: get(network, 'raw.data.0.phones.0.e164Format'),
                        email: isEmail ? query : null,
                        otherAttributes: {
                            id: {
                                label: 'Truecaller ID',
                                value: get(network, 'raw.data.0.id'),
                            },
                            enhanced: {
                                label: 'Enhanced',
                                value: get(network, 'raw.data.0.enhanced'),
                            },
                            countryCode: {
                                label: 'Country code',
                                value: get(
                                    network,
                                    'raw.data.0.phones.0.countryCode',
                                ),
                            },
                            carrier: {
                                label: 'Carrier',
                                value: get(
                                    network,
                                    'raw.data.0.phones.0.carrier',
                                ),
                            },
                            type: {
                                label: 'Type',
                                value: get(network, 'raw.data.0.phones.0.type'),
                            },
                            ns: {
                                label: 'NS',
                                value: get(network, 'raw.data.0.ns'),
                            },
                        },
                    };
                    break;

                case 'flickr':
                    data = {
                        network: 'Flickr',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.0.data.realname'),
                        image: get(network, 'raw.0.data.buddyicon.data.large'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url:
                            network.url ||
                            'https://www.flickr.com/photos/' +
                                get(network, 'raw.0.data.id'),
                        otherAttributes: {
                            id: {
                                label: 'Flickr ID',
                                value: get(network, 'raw.0.data.id'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.0.data.username'),
                            },
                            followersCount: {
                                label: 'Followers count',
                                value: get(
                                    network,
                                    'raw.0.data.followersCount',
                                ),
                            },
                            dateJoined: {
                                label: 'Date joined',
                                value: get(
                                    network,
                                    'raw.0.data.dateJoined.data',
                                ),
                            },
                        },
                    };
                    break;

                case 'substack':
                    data = {
                        network: 'Substack',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.name'),
                        image: get(network, 'raw.photo_url'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url:
                            network.url ||
                            'https://substack.com/' +
                                get(network, 'raw.username'), // might need an @ before username
                        otherAttributes: {
                            id: {
                                label: 'Substack ID',
                                value: get(network, 'raw.id'),
                            },
                            bio: {
                                label: 'Bio',
                                value: get(network, 'raw.bio'),
                            },
                            link: {
                                label: 'Link',
                                value: get(network, 'raw.link'),
                            },
                            bestsellerTier: {
                                label: 'Bestseller tier',
                                value: get(network, 'raw.bestseller_tier'),
                            },
                            isSubscribed: {
                                label: 'Is subscribed',
                                value: get(network, 'raw.is_subscribed'),
                            },
                            isBlocking: {
                                label: 'Is blocking',
                                value: get(network, 'raw.is_blocking'),
                            },
                            isMuting: {
                                label: 'Is muting',
                                value: get(network, 'raw.is_muting'),
                            },
                            isFollowing: {
                                label: 'Is following',
                                value: get(network, 'raw.is_following'),
                            },
                            canFollow: {
                                label: 'Can follow',
                                value: get(network, 'raw.can_follow'),
                            },
                        },
                    };
                    break;

                case 'eyecon':
                    data = {
                        network: 'Eyecon',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.name'),
                        image: get(network, 'raw.pic_url'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url: network.url || get(network, 'raw.profile_url'),
                        otherAttributes: {
                            facebookID: {
                                label: 'Facebook ID',
                                value: get(network, 'raw.fb_id'),
                            },
                        },
                    };
                    break;

                case 'gravatar':
                    data = {
                        network: 'Gravatar',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.entry.0.displayName'),
                        image: get(network, 'raw.entry.0.thumbnailUrl'),
                        url:
                            network.url ||
                            get(network, 'raw.entry.0.profileUrl'),
                        otherAttributes: {
                            id: {
                                label: 'Gravatar ID',
                                value: get(network, 'raw.entry.0.hash'),
                            },
                            preferredUsername: {
                                label: 'Preferred username',
                                value: get(
                                    network,
                                    'raw.entry.0.preferredUsername',
                                ),
                            },
                            hiddenAvatar: {
                                label: 'Hidden avatar',
                                value: get(
                                    network,
                                    'raw.entry.0.hidden_avatar',
                                ),
                            },
                            displayName: {
                                label: 'Display name',
                                value: get(network, 'raw.entry.0.displayName'),
                            },
                        },
                    };
                    break;

                case 'fitbit':
                    data = {
                        network: 'Fitbit',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.0.attributes.name'),
                        image: get(network, 'raw.0.attributes.avatar'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        otherAttributes: {
                            id: {
                                label: 'Fitbit ID',
                                value: get(network, 'raw.0.id'),
                            },
                            friend: {
                                label: 'Friend',
                                value: get(network, 'raw.0.attributes.friend'),
                            },
                            invited: {
                                label: 'Invited',
                                value: get(network, 'raw.0.attributes.invited'),
                            },
                            child: {
                                label: 'Child',
                                value: get(network, 'raw.0.attributes.child'),
                            },
                        },
                    };
                    break;

                case 'taringa':
                    data = {
                        network: 'Taringa',
                        source: serverSourceToClientSource(network.source),
                        name:
                            get(network, 'raw.firstname') +
                            ' ' +
                            get(network, 'raw.lastname'),
                        image: get(network, 'raw.avatar'),
                        url: network.url || get(network, 'raw.profile_url'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        otherAttributes: {
                            id: {
                                label: 'Taringa ID',
                                value: get(network, 'raw.id'),
                            },
                            createdAt: {
                                label: 'Account created',
                                value: new Date(get(network, 'raw.created')),
                            },
                            type: {
                                label: 'Type',
                                value: get(network, 'raw.type'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            firstName: {
                                label: 'First name',
                                value: get(network, 'raw.firstname'),
                            },
                            lastName: {
                                label: 'Last name',
                                value: get(network, 'raw.lastname'),
                            },
                            birthday: {
                                label: 'Birthday',
                                value: get(network, 'raw.birthday'),
                            },
                            avatar: {
                                label: 'Avatar',
                                value: get(network, 'raw.avatar'),
                            },
                            site: {
                                label: 'Site',
                                value: get(network, 'raw.site'),
                            },
                            gender: {
                                label: 'Gender',
                                value: get(network, 'raw.gender'),
                            },
                            country: {
                                label: 'Country',
                                value: get(network, 'raw.country'),
                            },
                            message: {
                                label: 'Message',
                                value: get(network, 'raw.message'),
                            },
                            verified: {
                                label: 'Verified',
                                value: get(network, 'raw.verified'),
                            },
                            deletedByUser: {
                                label: 'Deleted by user',
                                value: get(network, 'raw.deletedByUser'),
                            },
                            tags: {
                                label: 'Tags',
                                value: (get(network, 'raw.tags') || []).join(
                                    ', ',
                                ),
                            },
                            deletedOn: {
                                label: 'Deleted on',
                                value: get(network, 'raw.deletedOn'),
                            },
                            hardDeletedOn: {
                                label: 'Hard deleted on',
                                value: get(network, 'raw.hardDeletedOn'),
                            },
                            acceptedTyC: {
                                label: 'Accepted terms and conditions',
                                value: get(network, 'raw.acceptedTyC'),
                            },
                            rank: {
                                label: 'Rank',
                                value: get(network, 'raw.rank'),
                            },
                            suscriptions: {
                                label: 'Suscriptions',
                                value: get(network, 'raw.suscriptions'),
                            },
                        },
                    };
                    break;

                case 'foursquare':
                    data = {
                        network: 'Foursquare',
                        source: serverSourceToClientSource(network.source),
                        name:
                            get(network, 'raw.user.firstName') +
                            ' ' +
                            get(network, 'raw.user.lastName'),
                        image:
                            get(network, 'raw.user.photo.prefix') +
                            'original' +
                            get(network, 'raw.user.photo.suffix'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url:
                            network.url ||
                            'https://foursquare.com/u/' +
                                get(network, 'raw.user.id'),
                        otherAttributes: {
                            id: {
                                label: 'Foursquare ID',
                                value: get(network, 'raw.user.id'),
                            },
                            firstName: {
                                label: 'First name',
                                value: get(network, 'raw.user.firstName'),
                            },
                            lastName: {
                                label: 'Last name',
                                value: get(network, 'raw.user.lastName'),
                            },
                            handle: {
                                label: 'Handle',
                                value: get(network, 'raw.user.handle'),
                            },
                            privateProfile: {
                                label: 'Private profile',
                                value: get(network, 'raw.user.privateProfile'),
                            },
                            gender: {
                                label: 'Gender',
                                value: get(network, 'raw.user.gender'),
                            },
                            countryCode: {
                                label: 'Country code',
                                value: get(network, 'raw.user.countryCode'),
                            },
                            followingRelationship: {
                                label: 'Following relationship',
                                value: get(
                                    network,
                                    'raw.user.followingRelationship',
                                ),
                            },
                            homeCity: {
                                label: 'Home city',
                                value: get(network, 'raw.user.homeCity'),
                            },
                            bio: {
                                label: 'Bio',
                                value: get(network, 'raw.user.bio'),
                            },
                        },
                    };
                    break;

                case 'etsy':
                    data = {
                        accountExists: true,
                        network: 'Etsy',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.name'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                    };
                    break;

                case 'vk':
                    const vkUser = get(network, 'raw')[0];

                    if (!vkUser) {
                        break;
                    }

                    data = {
                        network: 'VK',
                        source: serverSourceToClientSource(network.source),
                        name:
                            get(vkUser, 'first_name') +
                            ' ' +
                            get(vkUser, 'last_name'),
                        image: get(vkUser, 'photo_200'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url:
                            network.url ||
                            'https://vk.com/id' + get(vkUser, 'id'),
                        otherAttributes: {
                            id: {
                                label: 'VK ID',
                                value: get(vkUser, 'id'),
                            },
                            firstName: {
                                label: 'First name',
                                value: get(vkUser, 'first_name'),
                            },
                            lastName: {
                                label: 'Last name',
                                value: get(vkUser, 'last_name'),
                            },
                            city: {
                                label: 'City',
                                value: get(vkUser, 'city.title'),
                            },
                            country: {
                                label: 'Country',
                                value: get(vkUser, 'country.title'),
                            },
                            canPost: {
                                label: 'Can post',
                                value: get(vkUser, 'can_post'),
                            },
                            canSeeAllPosts: {
                                label: 'Can see all posts',
                                value: get(vkUser, 'can_see_all_posts'),
                            },
                            isAdult: {
                                label: 'Is adult',
                                value: get(vkUser, 'is_adult'),
                            },
                            wallDefault: {
                                label: 'Wall default',
                                value: get(vkUser, 'wall_default'),
                            },
                            canWritePrivateMessage: {
                                label: 'Can write private message',
                                value: get(vkUser, 'can_write_private_message'),
                            },
                            blacklisted: {
                                label: 'Blacklisted',
                                value: get(vkUser, 'blacklisted'),
                            },
                            occupation: {
                                label: 'Occupation',
                                value: get(vkUser, 'occupation.name'),
                            },
                        },
                    };
                    break;

                case 'aboutme':
                    data = {
                        accountExists: true,
                        network: 'Aboutme',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.user_name'),
                        email:
                            get(network, 'raw.email') ||
                            (isEmail ? query : null),
                        phone: !isEmail ? query : null,
                    };
                    break;

                case 'garmin':
                    data = {
                        network: 'Garmin',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.userInfo.fullName'),
                        image: get(network, 'raw.userInfo')
                            ? get(
                                  network,
                                  'raw.userInfo.profileImageUrlLarge',
                              ) ||
                              get(
                                  network,
                                  'raw.userInfo.profileImageUrlMedium',
                              ) ||
                              get(network, 'raw.userInfo.profileImageUrlSmall')
                            : null,
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url:
                            network.url ||
                            'https://connect.garmin.com/modern/profile/' +
                                get(network, 'raw.userInfo.displayName'),
                        otherAttributes: {
                            id: {
                                label: 'Garmin ID',
                                value: get(network, 'raw.userId'),
                            },
                            displayName: {
                                label: 'Display name',
                                value: get(network, 'raw.userInfo.displayName'),
                            },
                            location: {
                                label: 'Location',
                                value: get(network, 'raw.userInfo.location'),
                            },
                            isBlocked: {
                                label: 'Is blocked',
                                value: get(network, 'raw.userInfo.isBlocked'),
                            },
                            levelUpdateDate: {
                                label: 'Level update date',
                                value: get(network, 'raw.levelUpdateDate'),
                            },
                            userLevel: {
                                label: 'User level',
                                value: get(network, 'raw.userLevel'),
                            },
                            userPoint: {
                                label: 'User point',
                                value: get(network, 'raw.userPoint'),
                            },
                            profileVisibility: {
                                label: 'Profile visibility',
                                value: get(network, 'raw.profileVisibility'),
                            },
                            activityVisibility: {
                                label: 'Activity visibility',
                                value: get(network, 'raw.activityVisibility'),
                            },
                            mutualConnection: {
                                label: 'Mutual connection',
                                value: get(network, 'raw.mutualConnection'),
                            },
                            connectionList: {
                                label: 'Connection list',
                                value: (
                                    get(network, 'raw.connectionList') || []
                                ).join(', '),
                            },
                            blockingList: {
                                label: 'Blocking list',
                                value: (
                                    get(network, 'raw.blockingList') || []
                                ).join(', '),
                            },
                        },
                    };
                    break;

                case 'protonmail':
                    data = {
                        accountExists: true,
                        network: 'Proton Mail',
                        source: serverSourceToClientSource(network.source),
                        name: '',
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                    };
                    break;

                case 'myfitnesspal':
                    data = {
                        accountExists: true,
                        network: 'MyFitnessPal',
                        source: serverSourceToClientSource(network.source),
                    };
                    break;

                case 'telegram':
                    if (!get(network, 'raw.users').length) {
                        return;
                    }

                    const user = get(network, 'raw.users')[0];

                    if (!user) {
                        return;
                    }

                    const telegramName =
                        user.first_name || user.last_name
                            ? user.first_name + ' ' + user.last_name
                            : user.username;

                    data = {
                        network: 'Telegram',
                        source: serverSourceToClientSource(network.source),
                        name: telegramName,
                        image: user.photo ? user.photo.stripped_thumb : null,
                        phone: user.phone || (!isEmail ? query : null),
                        otherAttributes: {
                            id: {
                                label: 'Telegram ID',
                                value: user.id,
                            },
                            deleted: {
                                label: 'Deleted',
                                value: user.deleted,
                            },
                            bot: {
                                label: 'Is a bot?',
                                value: user.bot ? 'Yes' : 'No',
                            },
                            verified: {
                                label: 'Verified',
                                value: user.verified ? 'Yes' : 'No',
                            },
                            scam: {
                                label: 'Scam',
                                value: user.scam ? 'Yes' : 'No',
                            },
                            fake: {
                                label: 'Fake',
                                value: user.fake ? 'Yes' : 'No',
                            },
                            premium: {
                                label: 'Premium',
                                value: user.premium ? 'Yes' : 'No',
                            },
                            firstName: {
                                label: 'First name',
                                value: user.first_name,
                            },
                            lastName: {
                                label: 'Last name',
                                value: user.last_name,
                            },
                            username: {
                                label: 'Username',
                                value: user.username,
                            },
                            wasOnline: {
                                label: 'Was online',
                                value: user.status
                                    ? user.status.was_online
                                    : null,
                            },
                            langCode: {
                                label: 'Language code',
                                value: user.lang_code,
                            },
                            usernames: {
                                label: 'Usernames',
                                value: (user.usernames || []).join(', '),
                            },
                        },
                    };
                    break;

                case 'weibo':
                    data = {
                        accountExists: true,
                        network: 'Weibo',
                        source: serverSourceToClientSource(network.source),
                    };
                    break;

                case 'mocospace':
                    const mocospaceName =
                        get(network, 'raw.first_name') ||
                        get(network, 'raw.last_name')
                            ? get(network, 'raw.first_name') +
                              ' ' +
                              get(network, 'raw.last_name')
                            : get(network, 'raw.username');

                    data = {
                        network: 'Mocospace',
                        source: serverSourceToClientSource(network.source),
                        name: mocospaceName,
                        image: get(network, 'raw.profile_pic'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        otherAttributes: {
                            id: {
                                label: 'Mocospace ID',
                                value: get(network, 'raw.user_id'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            profileCreated: {
                                label: 'Profile created',
                                value: get(network, 'raw.profile_created'),
                            },
                            gender: {
                                label: 'Gender',
                                value: get(network, 'raw.gender'),
                            },
                            age: {
                                label: 'Age',
                                value: get(network, 'raw.age'),
                            },
                            phoneVerified: {
                                label: 'Phone verified',
                                value: get(network, 'raw.phone_verified'),
                            },
                            about: {
                                label: 'About',
                                value: get(network, 'raw.about'),
                            },
                            firstName: {
                                label: 'First name',
                                value: get(network, 'raw.first_name'),
                            },
                            lastName: {
                                label: 'Last name',
                                value: get(network, 'raw.last_name'),
                            },
                            lastAccess: {
                                label: 'Last access',
                                value: get(network, 'raw.last_access'),
                            },
                            sexualOrientation: {
                                label: 'Sexual orientation',
                                value: get(network, 'raw.sexual_orientation'),
                            },
                            ethnicity: {
                                label: 'Ethnicity',
                                value: get(network, 'raw.ethnicity'),
                            },
                            relationshipStatus: {
                                label: 'Relationship status',
                                value: get(network, 'raw.relationship_status'),
                            },
                            education: {
                                label: 'Education',
                                value: get(network, 'raw.education'),
                            },
                            lookingFor: {
                                label: 'Looking for',
                                value: get(network, 'raw.looking_for'),
                            },
                            children: {
                                label: 'Children',
                                value: get(network, 'raw.children'),
                            },
                            profileLink: {
                                label: 'Profile link',
                                value: get(network, 'raw.profile_link'),
                            },
                        },
                    };
                    break;

                case 'picsart':
                    data = {
                        network: 'Picsart',
                        source: serverSourceToClientSource(network.source),
                        name:
                            get(network, 'raw.name') ||
                            get(network, 'raw.username'),
                        image: get(network, 'raw.photo'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url:
                            network.url ||
                            'https://picsart.com/u/' +
                                get(network, 'raw.username'),
                        otherAttributes: {
                            id: {
                                label: 'Picsart ID',
                                value: get(network, 'raw.id'),
                            },
                            name: {
                                label: 'Name',
                                value: get(network, 'raw.name'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            photo: {
                                label: 'Photo',
                                value: get(network, 'raw.photo'),
                            },
                            isVerified: {
                                label: 'Is verified',
                                value: get(network, 'raw.is_verified')
                                    ? 'Yes'
                                    : 'No',
                            },
                            followingCount: {
                                label: 'Following count',
                                value: get(network, 'raw.following_count'),
                            },
                            followersCount: {
                                label: 'Followers count',
                                value: get(network, 'raw.followers_count'),
                            },
                            likesCount: {
                                label: 'Likes count',
                                value: get(network, 'raw.likes_count'),
                            },
                            photosCount: {
                                label: 'Photos count',
                                value: get(network, 'raw.photos_count'),
                            },
                            stickersPublicCount: {
                                label: 'Stickers public count',
                                value: get(
                                    network,
                                    'raw.stickers_public_count',
                                ),
                            },
                            tags: {
                                label: 'Tags',
                                value: (get(network, 'raw.tags') || []).join(
                                    ', ',
                                ),
                            },
                            isFollow: {
                                label: 'Is follow',
                                value: get(network, 'raw.is_follow')
                                    ? 'Yes'
                                    : 'No',
                            },
                            isBlocked: {
                                label: 'Is blocked',
                                value: get(network, 'raw.is_blocked')
                                    ? 'Yes'
                                    : 'No',
                            },
                        },
                    };
                    break;

                case 'vivino':
                    const vivinoAddress = get(network, 'raw.address')
                        ? `${get(network, 'raw.address.street')}, ${get(
                              network,
                              'raw.address.city',
                          )}, ${get(network, 'raw.address.state')}, ${get(
                              network,
                              'raw.address.country',
                          )}`
                        : null;

                    data = {
                        network: 'Vivino',
                        source: serverSourceToClientSource(network.source),
                        name: network.address
                            ? network.address.phone
                            : get(network, 'raw.alias'),
                        image: get(network, 'raw.image')
                            ? get(network, 'raw.image.location')
                            : null,
                        email: isEmail ? query : null,
                        phone: network.address
                            ? network.address.phone
                            : !isEmail
                            ? query
                            : null,
                        url:
                            network.url ||
                            'https://www.vivino.com/users/' +
                                get(network, 'raw.id'),
                        otherAttributes: {
                            id: {
                                label: 'Vivino ID',
                                value: get(network, 'raw.id'),
                            },
                            seoName: {
                                label: 'SEO name',
                                value: get(network, 'raw.seo_name'),
                            },
                            alias: {
                                label: 'Alias',
                                value: get(network, 'raw.alias'),
                            },
                            isFeatured: {
                                label: 'Is featured',
                                value: get(network, 'raw.is_featured')
                                    ? 'Yes'
                                    : 'No',
                            },
                            isPremium: {
                                label: 'Is premium',
                                value: get(network, 'raw.is_premium')
                                    ? 'Yes'
                                    : 'No',
                            },
                            visibility: {
                                label: 'Visibility',
                                value: get(network, 'raw.visibility'),
                            },
                            language: {
                                label: 'Language',
                                value: get(network, 'raw.language'),
                            },
                            bio: {
                                label: 'Bio',
                                value: get(network, 'raw.bio'),
                            },
                            website: {
                                label: 'Website',
                                value: get(network, 'raw.website'),
                            },
                            address: {
                                label: 'Address',
                                value: vivinoAddress,
                            },
                        },
                    };
                    break;

                case 'chess':
                    data = {
                        network: 'Chess.com',
                        source: serverSourceToClientSource(network.source),
                        name:
                            get(network, 'raw.name') ||
                            get(network, 'raw.username'),
                        image: get(network, 'raw.avatar_url'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url:
                            network.url ||
                            'https://www.chess.com/member/' +
                                get(network, 'raw.username'),
                        otherAttributes: {
                            id: {
                                label: 'Chess.com ID',
                                value: get(network, 'raw.id'),
                            },
                            uuid: {
                                label: 'Chess.com UUID',
                                value: get(network, 'raw.uuid'),
                            },
                            name: {
                                label: 'Name',
                                value: get(network, 'raw.name'),
                            },
                            countryId: {
                                label: 'Country ID',
                                value: get(network, 'raw.country_id'),
                            },
                            avatarUrl: {
                                label: 'Avatar URL',
                                value: get(network, 'raw.avatar_url'),
                            },
                            memberUrl: {
                                label: 'Member URL',
                                value: get(network, 'raw.member_url'),
                            },
                            lastLoginDate: {
                                label: 'Last login date',
                                value: get(network, 'raw.last_login_date'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            points: {
                                label: 'Points',
                                value: get(network, 'raw.points'),
                            },
                            countryName: {
                                label: 'Country name',
                                value: get(network, 'raw.country_name'),
                            },
                            memberSince: {
                                label: 'Member since',
                                value: get(network, 'raw.member_since'),
                            },
                            about: {
                                label: 'About',
                                value: get(network, 'raw.about'),
                            },
                            bestRating: {
                                label: 'Best rating',
                                value: get(network, 'raw.best_rating'),
                            },
                            bestRatingType: {
                                label: 'Best rating type',
                                value: get(network, 'raw.best_rating_type'),
                            },
                            isOnline: {
                                label: 'Is online',
                                value: get(network, 'raw.is_online')
                                    ? 'Yes'
                                    : 'No',
                            },
                            isInLivechess: {
                                label: 'Is in livechess',
                                value: get(network, 'raw.is_in_livechess')
                                    ? 'Yes'
                                    : 'No',
                            },
                            onlineStatus: {
                                label: 'Online status',
                                value: get(network, 'raw.online_status'),
                            },
                            isEnabled: {
                                label: 'Is enabled',
                                value: get(network, 'raw.is_enabled')
                                    ? 'Yes'
                                    : 'No',
                            },
                            membershipLevel: {
                                label: 'Membership level',
                                value: get(network, 'raw.membership_level'),
                            },
                            membershipCode: {
                                label: 'Membership code',
                                value: get(network, 'raw.membership_code'),
                            },
                            userId: {
                                label: 'User ID',
                                value: get(network, 'raw.user_id'),
                            },
                            hasImage: {
                                label: 'Has image',
                                value: get(network, 'raw.has_image')
                                    ? 'Yes'
                                    : 'No',
                            },
                            flairCode: {
                                label: 'Flair code',
                                value: get(network, 'raw.flair_code'),
                            },
                        },
                    };
                    break;

                case 'imageshack':
                    data = {
                        accountExists: true,
                        network: 'Imageshack',
                        name: get(network, 'raw.username'),
                        source: serverSourceToClientSource(network.source),
                    };
                    break;

                case 'trello':
                    data = {
                        network: 'Trello',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.fullName'),
                        image: get(network, 'raw.avatarUrl'),
                        email: network.email || isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url:
                            network.url ||
                            'https://trello.com/' +
                                get(network, 'raw.username'),
                        otherAttributes: {
                            id: {
                                label: 'Trello ID',
                                value: get(network, 'raw.id'),
                            },
                            aaId: {
                                label: 'Trello AA ID',
                                value: get(network, 'raw.aaId'),
                            },
                            activityBlocked: {
                                label: 'Activity blocked',
                                value: get(network, 'raw.activityBlocked')
                                    ? 'Yes'
                                    : 'No',
                            },
                            avatarUrl: {
                                label: 'Avatar URL',
                                value: get(network, 'raw.avatarUrl'),
                            },
                            bio: {
                                label: 'Bio',
                                value: get(network, 'raw.bio'),
                            },
                            confirmed: {
                                label: 'Confirmed',
                                value: get(network, 'raw.confirmed')
                                    ? 'Yes'
                                    : 'No',
                            },
                            fullName: {
                                label: 'Full name',
                                value: get(network, 'raw.fullName'),
                            },
                            idEnterprise: {
                                label: 'Enterprise ID',
                                value: get(network, 'raw.idEnterprise'),
                            },
                            initials: {
                                label: 'Initials',
                                value: get(network, 'raw.initials'),
                            },
                            memberType: {
                                label: 'Member type',
                                value: get(network, 'raw.memberType'),
                            },
                            url: {
                                label: 'URL',
                                value: get(network, 'raw.url'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            status: {
                                label: 'Status',
                                value: get(network, 'raw.status'),
                            },
                            email: {
                                label: 'Email',
                                value: get(network, 'raw.email'),
                            },
                        },
                    };
                    break;

                case 'poshmark':
                    data = {
                        network: 'Poshmark',
                        source: serverSourceToClientSource(network.source),
                        name:
                            get(network, 'raw.full_name') ||
                            get(network, 'raw.username'),
                        image: get(network, 'raw.picture_url'),
                        email: isEmail ? query : null,
                        phone:
                            network.mobile_phone_verified || !isEmail
                                ? query
                                : null,
                        url:
                            network.url ||
                            'https://poshmark.com/closet/' +
                                get(network, 'raw.username'),
                        otherAttributes: {
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            displayHqndle: {
                                label: 'Display handle',
                                value: get(network, 'raw.display_handle'),
                            },
                            fullName: {
                                label: 'Full name',
                                value: get(network, 'raw.full_name'),
                            },
                            userID: {
                                label: 'User ID',
                                value: get(network, 'raw.user_id'),
                            },
                            fbUserID: {
                                label: 'Facebook user ID',
                                value: get(network, 'raw.fb_user_id'),
                            },
                            gpUserID: {
                                label: 'Google+ user ID',
                                value: get(network, 'raw.gp_user_id'),
                            },
                            apUserID: {
                                label: 'Apple user ID',
                                value: get(network, 'raw.ap_user_id'),
                            },
                            homeDomain: {
                                label: 'Home domain',
                                value: get(network, 'raw.home_domain'),
                            },
                            searchVisibility: {
                                label: 'Search visibility',
                                value: get(network, 'raw.search_visibility')
                                    ? 'Yes'
                                    : 'No',
                            },
                            gender: {
                                label: 'Gender',
                                value: get(network, 'raw.gender'),
                            },
                            status: {
                                label: 'Status',
                                value: get(network, 'raw.status'),
                            },
                            city: {
                                label: 'City',
                                value: get(network, 'raw.city'),
                            },
                            state: {
                                label: 'State',
                                value: get(network, 'raw.state'),
                            },
                            regMethod: {
                                label: 'Registration method',
                                value: get(network, 'raw.reg_method'),
                            },
                            regApp: {
                                label: 'Registration app',
                                value: get(network, 'raw.reg_app'),
                            },
                            mobilePhoneVerified: {
                                label: 'Mobile phone verified',
                                value: get(
                                    network,
                                    'raw.mobile_phone_verified',
                                ),
                            },
                            identityVerificationState: {
                                label: 'Identity verification state',
                                value: get(
                                    network,
                                    'raw.identity_verification_state',
                                ),
                            },
                            pictureUrl: {
                                label: 'Picture URL',
                                value: get(network, 'raw.picture_url'),
                            },
                            createdAt: {
                                label: 'Created at',
                                value: get(network, 'raw.created_at'),
                            },
                            pinterestUsername: {
                                label: 'Pinterest username',
                                value: get(network, 'raw.pinterest_username'),
                            },
                            tumblrUsername: {
                                label: 'Tumblr username',
                                value: get(network, 'raw.tumblr_username'),
                            },
                            twitterUsername: {
                                label: 'Twitter username',
                                value: get(network, 'raw.twitter_username'),
                            },
                            facebookUsername: {
                                label: 'Facebook username',
                                value: get(network, 'raw.facebook_username'),
                            },
                        },
                    };
                    break;

                case 'signal':
                    data = {
                        accountExists: true,
                        network: 'Signal',
                        source: serverSourceToClientSource(network.source),
                        phone: network.number,
                    };
                    break;

                case 'bereal':
                    data = {
                        network: 'BeReal',
                        source: serverSourceToClientSource(network.source),
                        name:
                            get(network, 'raw.fullname') ||
                            get(network, 'raw.username'),
                        image: get(network, 'raw.profilePicture.url'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        otherAttributes: {
                            id: {
                                label: 'BeReal ID',
                                value: get(network, 'raw.id'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            fullname: {
                                label: 'Full name',
                                value: get(network, 'raw.fullname'),
                            },
                            biography: {
                                label: 'Biography',
                                value: get(network, 'raw.biography'),
                            },
                            createdAt: {
                                label: 'Created at',
                                value: get(network, 'raw.createdAt'),
                            },
                            isRealPeople: {
                                label: 'Is real people',
                                value: get(network, 'raw.isRealPeople')
                                    ? 'Yes'
                                    : 'No',
                            },
                            userFreshness: {
                                label: 'User freshness',
                                value: get(network, 'raw.userFreshness'),
                            },
                            streakLength: {
                                label: 'Streak length',
                                value: get(network, 'raw.streakLength'),
                            },
                        },
                    };
                    break;

                case 'scrabblego':
                    data = {
                        network: 'Scrabble Go',
                        source: serverSourceToClientSource(network.source),
                        name:
                            get(network, 'raw.name') ||
                            get(network, 'raw.username'),
                        image: get(network, 'raw.pic_url'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        otherAttributes: {
                            id: {
                                label: 'Scrabble Go ID',
                                value: get(network, 'raw.id'),
                            },
                            name: {
                                label: 'Name',
                                value: get(network, 'raw.name'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            lastPlayed: {
                                label: 'Last played',
                                value: get(network, 'raw.last_played'),
                            },
                            creationDate: {
                                label: 'Creation date',
                                value: get(network, 'raw.creation_date'),
                            },
                            versionInstalled: {
                                label: 'Version installed',
                                value: get(network, 'raw.version_installed'),
                            },
                            picUrl: {
                                label: 'Picture URL',
                                value: get(network, 'raw.pic_url'),
                            },
                        },
                    };
                    break;

                case 'linkedin':
                    if (
                        (network.url && network.url.includes('undefined')) ||
                        !get(network, 'raw.publicIdentifier')
                    ) {
                        break;
                    }

                    const liLink =
                        network.url ||
                        get(network, 'raw.linkedInUrl') ||
                        'https://www.linkedin.com/in/' +
                            get(network, 'raw.publicIdentifier');

                    const linkedInImage = network.raw.picture
                        ? network.raw.picture.rootUrl +
                          network.raw.picture['com.linkedin.common.VectorImage']
                              .artifacts[3].fileIdentifyingUrlPathSegment
                        : null;
                    data = {
                        network: 'LinkedIn',
                        source: serverSourceToClientSource(network.source),
                        name:
                            get(network, 'raw.firstName') +
                            ' ' +
                            get(network, 'raw.lastName'),
                        image: linkedInImage,
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url: liLink,
                        otherAttributes: {
                            firstName: {
                                label: 'First name',
                                value: get(network, 'raw.firstName'),
                            },
                            lastName: {
                                label: 'Last name',
                                value: get(network, 'raw.lastName'),
                            },
                            occupation: {
                                label: 'Occupation',
                                value: get(network, 'raw.occupation'),
                            },
                            publicIdentifier: {
                                label: 'Public identifier',
                                value: get(network, 'raw.publicIdentifier'),
                            },
                        },
                    };
                    break;

                case 'touchtunes':
                    data = {
                        network: 'TouchTunes',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.username'),
                        image:
                            get(network, 'raw.picture.120') ||
                            get(network, 'raw.picture.75') ||
                            get(network, 'raw.picture.50'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        otherAttributes: {
                            id: {
                                label: 'TouchTunes ID',
                                value: get(network, 'raw.id'),
                            },
                            username: {
                                label: 'Username',
                                value: get(network, 'raw.username'),
                            },
                            created: {
                                label: 'Created',
                                value: get(network, 'raw.created'),
                            },
                            currentLevelID: {
                                label: 'Current level ID',
                                value: get(network, 'raw.current_level_id'),
                            },
                            stageName: {
                                label: 'Stage name',
                                value: get(network, 'raw.stage_name'),
                            },
                            loyaltyLevel: {
                                label: 'Loyalty level',
                                value: get(
                                    network,
                                    'raw.loyalty_level.reward_level',
                                ),
                            },
                            relationship: {
                                label: 'Relationship',
                                value: get(network, 'raw.relationship'),
                            },
                        },
                    };
                    break;

                case 'playgames':
                    data = {
                        network: 'Play Games',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.display_name'),
                        image: get(network, 'raw.avatar_url'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        otherAttributes: {
                            playedGamesCount: {
                                label: 'Played games count',
                                value: get(network, 'raw.played_games.length'),
                            },
                            achievementsCount: {
                                label: 'Achievements count',
                                value: get(network, 'raw.achievements.length'),
                            },
                            profileId: {
                                label: 'Profile ID',
                                value: get(network, 'raw.profile.id'),
                            },
                            lastPlayed: {
                                label: 'Last played',
                                value: get(
                                    network,
                                    'raw.profile.last_played_app.timestamp_millis',
                                )
                                    ? new Date(
                                          get(
                                              network,
                                              'raw.profile.last_played_app.timestamp_millis',
                                          ),
                                      )
                                    : null,
                            },
                            lastLevelUp: {
                                label: 'Last level up',
                                value: get(
                                    network,
                                    'raw.profile.last_level_up_timestamp_millis',
                                )
                                    ? new Date(
                                          get(
                                              network,
                                              'raw.profile.last_level_up_timestamp_millis',
                                          ),
                                      )
                                    : null,
                            },
                            displayName: {
                                label: 'Display name',
                                value: get(network, 'raw.profile.display_name'),
                            },
                            title: {
                                label: 'Title',
                                value: get(network, 'raw.profile.title'),
                            },
                            avatarUrl: {
                                label: 'Avatar URL',
                                value: get(network, 'raw.profile.avatar_url'),
                            },
                            gamertag: {
                                label: 'Gamertag',
                                value: get(network, 'raw.profile.gamertag'),
                            },
                        },
                    };
                    break;

                case 'qq':
                    data = {
                        network: 'QQ',
                        source: serverSourceToClientSource(network.source),
                        phone:
                            get(network, 'raw.phone_number') ||
                            (!isEmail ? query : null),
                        email:
                            get(network, 'raw.email') ||
                            (isEmail ? query : null),
                        image: get(network, 'raw.profile_pic'),
                        url:
                            network.url ||
                            'https://user.qzone.qq.com/' +
                                get(network, 'raw.qq_id'),
                        otherAttributes: {
                            id: {
                                label: 'QQ ID',
                                value: get(network, 'raw.qq_id'),
                            },
                            email: {
                                label: 'Email',
                                value: get(network, 'raw.email'),
                            },
                            region: {
                                label: 'Region',
                                value: get(network, 'raw.region'),
                            },
                            leagueOfLegendsID: {
                                label: 'League of Legends ID',
                                value: get(network, 'raw.league_of_legends_id'),
                            },
                            weiboLink: {
                                label: 'Weibo link',
                                value: get(network, 'raw.weibo_link'),
                            },
                            weiboID: {
                                label: 'Weibo ID',
                                value: get(network, 'raw.weibo_id'),
                            },
                        },
                    };
                    break;

                case 'dropbox':
                    if (network.raw.registered) {
                        data = {
                            accountExists: true,
                            network: 'Dropbox',
                            source: serverSourceToClientSource(network.source),
                        };
                    } else {
                        data = {
                            network: 'Dropbox',
                            name: get(network, 'raw.name.display_name'),
                            image: get(network, 'raw.profile_photo_url'),
                            email: get(network, 'raw.email'),
                            source: serverSourceToClientSource(network.source),
                            otherAttributes: {
                                teamID: {
                                    label: 'Team ID',
                                    value: get(network, 'raw.team_id'),
                                },
                                disabled: {
                                    label: 'Disabled',
                                    value: get(network, 'raw.disabled'),
                                },
                                emailVerified: {
                                    label: 'Email verified',
                                    value: get(network, 'raw.email_verified'),
                                },
                            },
                        };
                    }
                    break;

                case 'hibp':
                    if (Array.isArray(network.raw)) {
                        network.raw.forEach((hit) => {
                            const d = {
                                accountExists: true, // different ui for only showing if a user's account exists, and no other info
                                network: hit.Name,
                                source: serverSourceToClientSource(
                                    network.source,
                                ),
                                ...(isEmail
                                    ? { email: query }
                                    : { phone: query }),
                            };

                            if (profiles[hit.Name]) {
                                profiles[hit.Name].push(d);
                            } else {
                                profiles[hit.Name] = [d];
                            }
                        });
                    }
                    break;

                case 'instagram':
                    data = {
                        accountExists: true,
                        network: 'Instagram',
                        source: serverSourceToClientSource(network.source),
                    };
                    break;

                case 'airbnb':
                    if (get(network, 'raw.user')) {
                        const airbnbId =
                            get(network, 'raw.user.id') ||
                            get(network, 'raw.user.user_id');
                        data = {
                            network: 'Airbnb',
                            name: get(network, 'raw.user.first_name'),
                            source: serverSourceToClientSource(network.source),
                            image: get(network, 'raw.user.picture_url'),
                            email: isEmail ? query : null,
                            phone: get(network, 'raw.user.phone_number'),
                            url:
                                network.url ||
                                get(network, 'raw.user.url') ||
                                'https://www.airbnb.co.uk/users/show/' +
                                    airbnbId,
                            otherAttributes: {
                                id: {
                                    label: 'Airbnb ID',
                                    value: airbnbId,
                                },
                                url: {
                                    label: 'Airbnb URL',
                                    value: get(network, 'raw.user.url'),
                                },
                                about: {
                                    label: 'About',
                                    value: get(network, 'raw.user.about'),
                                },
                                trips: {
                                    label: 'Trips taken',
                                    value: get(network, 'raw.user.trips'),
                                },
                                location: {
                                    label: 'User location',
                                    value: get(network, 'raw.user.location'),
                                },
                                createdAt: {
                                    label: 'Created at',
                                    value: get(network, 'raw.user.created_at'),
                                },
                                firstName: {
                                    label: 'First name',
                                    value: get(network, 'raw.user.first_name'),
                                },
                                listingsCount: {
                                    label: 'Listings count',
                                    value: get(
                                        network,
                                        'raw.user.listings_count',
                                    ),
                                },
                                reviewsCount: {
                                    label: 'Reviews count',
                                    value: get(
                                        network,
                                        'raw.user.reviewee_count',
                                    ),
                                },
                                identityVerified: {
                                    label: 'Identity verified',
                                    value: get(
                                        network,
                                        'raw.user.identity_verified',
                                    ),
                                },
                                totalListingsCount: {
                                    label: 'Total listings count',
                                    value: get(
                                        network,
                                        'raw.user.total_listings_count',
                                    ),
                                },
                            },
                        };
                    }
                    break;

                case 'goodreads':
                    data = {
                        network: 'Goodreads',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.name'),
                        image: get(network, 'raw.image_url'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url: network.url || get(network, 'raw.link'),
                        otherAttributes: {
                            id: {
                                label: 'Goodreads ID',
                                value: get(network, 'raw.id'),
                            },
                            link: {
                                label: 'Goodreads link',
                                value: get(network, 'raw.link'),
                            },
                            name: {
                                label: 'Name',
                                value: get(network, 'raw.name'),
                            },
                            imageUrl: {
                                label: 'Image URL',
                                value: get(network, 'raw.image_url'),
                            },
                            friendsCount: {
                                label: 'Friends count',
                                value: get(network, 'raw.friends_count'),
                            },
                            reviewsCount: {
                                label: 'Reviews count',
                                value: get(network, 'raw.reviews_count'),
                            },
                        },
                    };
                    break;

                case 'smule':
                    data = {
                        network: 'Smule',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.handle'),
                        image: get(network, 'raw.picUrl'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url:
                            network.url ||
                            'https://www.smule.com/' +
                                (get(network, 'raw.handle') ||
                                    get(network, 'raw.accountIcon.handle')),
                        otherAttributes: {
                            jid: {
                                label: 'JID',
                                value: get(network, 'raw.jid'),
                            },
                            handle: {
                                label: 'Handle',
                                value: get(network, 'raw.handle'),
                            },
                            picUrl: {
                                label: 'Picture URL',
                                value: get(network, 'raw.picUrl'),
                            },
                            subApps: {
                                label: 'Sub apps',
                                value: (get(network, 'raw.subApps') || []).join(
                                    ', ',
                                ),
                            },
                            accountId: {
                                label: 'Account ID',
                                value: get(network, 'raw.accountId'),
                            },
                            picUrlType: {
                                label: 'Picture URL type',
                                value: get(network, 'raw.picUrlType'),
                            },
                            verified: {
                                label: 'Verified',
                                value: get(network, 'raw.verifiedType'),
                            },
                        },
                    };
                    break;
                case 'rumble':
                    data = {
                        network: 'Rumble',
                        source: serverSourceToClientSource(network.source),
                        name: get(network, 'raw.name'),
                        image: get(network, 'raw.thumb'),
                        email: isEmail ? query : null,
                        phone: !isEmail ? query : null,
                        url:
                            network.url ||
                            `https://rumble.com/user/${get(
                                network,
                                'raw.slug',
                            )}`,
                        otherAttributes: {
                            joinDate: {
                                label: 'Join date',
                                value: get(network, 'raw.enriched.join_date'),
                            },
                            followers: {
                                label: 'Followers',
                                value: get(network, 'raw.followers'),
                            },
                            following: {
                                label: 'Following',
                                value: get(network, 'raw.following'),
                            },
                            videos: {
                                label: 'Videos',
                                value: get(network, 'raw.videos'),
                            },
                            type: {
                                label: 'Type',
                                value: get(network, 'raw.type'),
                            },
                        },
                    };
                    break;
            }
        }

        if (!Object.keys(data).length) {
            return;
        }

        // if the network doesn't exist, create it and add the data
        if (!profiles[network.network]) {
            profiles[network.network] = [data];

            return;
        }

        // if the network exists, push the data to it
        profiles[network.network].push(data);
    });

    // do we have networks than can populate a facebook url?
    const runkeeperNetwork = profiles.runkeeper && profiles.runkeeper[0];
    const facebookNetwork = profiles.facebook && profiles.facebook[0];
    const mindjoltNetwork = results.find((r) =>
        (r.network || '').includes('mindjolt'),
    );
    const zyngaNetwork = results.find((r) =>
        (r.network || '').includes('zynga'),
    );

    if (runkeeperNetwork || mindjoltNetwork || zyngaNetwork) {
        // get best id from networks to populate the url
        const fbID = (() => {
            if (runkeeperNetwork && runkeeperNetwork.otherAttributes) {
                if (
                    !runkeeperNetwork.otherAttributes.fbuid ||
                    !runkeeperNetwork.otherAttributes.facebookId
                )
                    return;

                return (
                    runkeeperNetwork.otherAttributes.fbuid.value ||
                    runkeeperNetwork.otherAttributes.facebookId.value
                );
            }

            const mindjoltFBID =
                get(mindjoltNetwork, 'raw.facebook_id') ||
                get(mindjoltNetwork, 'raw.facebook_user_id');

            if (mindjoltNetwork && mindjoltFBID) {
                return mindjoltFBID;
            }

            const zyngaFBID = get(zyngaNetwork, 'raw.facebook_account_id');

            if (zyngaNetwork && zyngaFBID) {
                return zyngaFBID;
            }

            return null;
        })();

        // do we have a facebook network already?
        if (fbID && facebookNetwork) {
            const emptyUrlFB = facebookNetwork.url === '';
            // does is the url unpoulated? if so add it
            if (emptyUrlFB) {
                profiles.facebook[0].url = 'https://www.facebook.com/' + fbID;
            }
        }

        // if no facebook network, create one with the url
        if (fbID && !facebookNetwork) {
            // find first valid name in profiles
            const name =
                Object.values(profiles)
                    .map((p) => p[0].name)
                    .filter(Boolean)[0] || 'Facebook User';

            profiles.facebook = [
                {
                    network: 'Facebook',
                    source: SOURCE.NW_DATA,
                    name,
                    url: 'https://www.facebook.com/' + fbID,
                    otherAttributes: {
                        id: {
                            label: 'Facebook ID',
                            value: fbID,
                        },
                    },
                },
            ];
        }
    }

    const googleNetwork = profiles.google && profiles.google[0];
    const mapsNetwork = results.find((r) => r.network === 'maps');

    if (googleNetwork && googleNetwork.otherAttributes && mapsNetwork) {
        const mapsHasData =
            (mapsNetwork.raw.reviews && mapsNetwork.raw.reviews.length > 0) ||
            (mapsNetwork.raw.photos && mapsNetwork.raw.photos.length > 0);
        const query = mapsNetwork.value.current;
        const isEmail = query.includes('@');

        if (mapsHasData) {
            const googleMaps = {
                network: 'Google Maps',
                source: SOURCE.NW_OSINT,
                url:
                    'https://www.google.com/maps/contrib/' +
                    googleNetwork.otherAttributes.id.value +
                    '/reviews',
                name: googleNetwork.name,
                image: googleNetwork.image,
                email: isEmail ? query : null,
                phone: !isEmail ? query : null,
                otherAttributes: {
                    reviewsCount: {
                        label: 'Submitted reviews',
                        value: mapsNetwork.raw.reviews.length,
                    },
                    photosCount: {
                        label: 'Photos',
                        value: mapsNetwork.raw.photos.length,
                    },
                    visibility: {
                        label: 'Visibility',
                        value: mapsNetwork.raw.visibility,
                    },
                },
            };

            profiles.googleMaps = [googleMaps];
        }
    }

    return profiles;
}
